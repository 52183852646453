import getConfig from 'config';

const { NEXT_PUBLIC_DOCS } = getConfig();

export enum DocumentationChapter {
  'order-builder' = 'order-builder',
  markeplace = 'markeplace',
  'my-files' = 'my-files',
  'all-orders' = 'all-orders',
  order = 'order',
  'compatibility-issues' = 'compatibility-issues',
  'enter-marketplace' = 'enter-marketplace',
  'demo-account' = 'demo-account',
  'web3-account' = 'web3-account',
  offer = 'offer',
  info = 'info',
  'upload-content' = 'upload-content',
  'edit-content' = 'edit-content',
  'extend-lease' = 'extend-lease',
  'check-confidentiality' = 'check-confidentiality',
  'confirm-transactions' = 'confirm-transactions',
  'log-in' = 'log-in',
  upload = 'upload',
  'place-order' = 'place-order',
  passphrase = 'passphrase',
  'token-limits' = 'token-limits',
  'offer-builder' = 'offer-builder'
}

export const documentation = {
  [DocumentationChapter['order-builder']]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/order-builder`,
  [DocumentationChapter.markeplace]: `${NEXT_PUBLIC_DOCS}/marketplace/marketplace/list`,
  [DocumentationChapter['my-files']]: `${NEXT_PUBLIC_DOCS}/marketplace/my-content/my-files`,
  [DocumentationChapter['all-orders']]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/all-orders`,
  [DocumentationChapter.order]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/order`,
  [DocumentationChapter['compatibility-issues']]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/order-builder/compatibility`,
  [DocumentationChapter['enter-marketplace']]: `${NEXT_PUBLIC_DOCS}/marketplace/account`,
  [DocumentationChapter['demo-account']]: `${NEXT_PUBLIC_DOCS}/marketplace/account/demo`,
  [DocumentationChapter['web3-account']]: `${NEXT_PUBLIC_DOCS}/marketplace/account/web3`,
  [DocumentationChapter.offer]: `${NEXT_PUBLIC_DOCS}/marketplace/marketplace/offer`,
  [DocumentationChapter.info]: `${NEXT_PUBLIC_DOCS}/marketplace/my-content/my-files/info`,
  [DocumentationChapter['upload-content']]: `${NEXT_PUBLIC_DOCS}/marketplace/my-content/my-files/upload-content`,
  [DocumentationChapter['edit-content']]: `${NEXT_PUBLIC_DOCS}/marketplace/my-content/my-files/edit-content`,
  [DocumentationChapter['extend-lease']]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/order#extend-lease`,
  [DocumentationChapter['check-confidentiality']]: `${NEXT_PUBLIC_DOCS}/marketplace/services/check-confidentiality`,
  [DocumentationChapter['confirm-transactions']]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/order-builder/checkout`,
  [DocumentationChapter['log-in']]: `${NEXT_PUBLIC_DOCS}/marketplace/guides/log-in`,
  [DocumentationChapter.upload]: `${NEXT_PUBLIC_DOCS}/marketplace/guides/upload`,
  [DocumentationChapter['place-order']]: `${NEXT_PUBLIC_DOCS}/marketplace/guides/place-order`,
  [DocumentationChapter.passphrase]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/order/passphrase`,
  [DocumentationChapter['token-limits']]: `${NEXT_PUBLIC_DOCS}/marketplace/limitations#token-limits`,
  [DocumentationChapter['offer-builder']]: `${NEXT_PUBLIC_DOCS}/marketplace/orders/offer-builder`,
};

export const support = 'https://support.superprotocol.com';

export const discord = 'https://discord.gg/superprotocol';

export const askQuestion = 'https://superprotocol.zendesk.com/hc/en-us/requests/new';
