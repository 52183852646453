import React, { FC, memo, useMemo } from 'react';
import { useField } from 'formik';

import { Checkbox } from './Checkbox';
import { CheckboxFormikProps } from './types';

export const CheckboxFormik: FC<CheckboxFormikProps> = memo(({
  name,
  onChange = () => {},
  showError = true,
  checkTouched = true,
  isInvalid: isInvalidProp,
  ...rest
}) => {
  const [, { value, error, touched }, { setValue }] = useField(name);
  const isInvalid: boolean = useMemo(
    () => !!(error && (!checkTouched || touched)) || !!isInvalidProp,
    [error, touched, checkTouched, isInvalidProp],
  );

  return (
    <Checkbox
      {...rest}
      isInvalid={isInvalid}
      error={error}
      showError={showError}
      checked={!!value}
      onChange={(val, event) => {
        setValue(val);
        onChange(val, event);
      }}
      name={name}
    />
  );
});
