'use client';

import { memo, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';

import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { announcementsSelector, setFirstTimeVisited } from 'lib/features/announcements';
// import { Announcements } from 'components/Announcements';
import { useAuth } from 'hooks/auth/useAuth';
import { Box } from 'uikitv2/Box';
import classes from './Header.module.scss';
import { Logo } from './Logo';
import { Menu } from './Menu';
import { useReferrer } from './useReferrer';

// const Modal = dynamic(() => import('../../uikitv2/Modal/Modal'), { ssr: false });
const Account = dynamic(() => import('../Account/Account'), { ssr: false });

export const Header = memo(() => {
  const dispatch = useAppDispatch();
  // const { firstTimeVisited, isOpen } = useAppSelector(announcementsSelector);
  const { isUserConnected, isProviderSPProvider } = useAuth();
  const router = useRouter();
  useReferrer();
  const onClose = useCallback(() => {
    dispatch(setFirstTimeVisited());
  }, [dispatch]);

  const onClick = useCallback((e) => {
    e.preventDefault();
    router.push('/', undefined);
  }, [router]);

  return (
    <>
      <Box className={classes.wrap} Tag="header" data-testid="header" justifyContent="center">
        <Box className={classes.content} alignItems="center" justifyContent="space-between">
          <Box className={classes.menu} alignItems="center">
            <Logo {...{ onClick }} />
            <Menu {...{ isUserConnected, isProviderSPProvider }} />
          </Box>
          <Account />
        </Box>
      </Box>
      {/* <Modal
        show={!firstTimeVisited || isOpen}
        onClose={onClose}
        dialogClassName={classes['modal-dialog']}
        contentClassName={classes['modal-cntent']}
        bodyClassName={classes['modal-body']}
      >
        {(!firstTimeVisited || isOpen) && <Announcements />}
      </Modal> */}
    </>
  );
});

export default Header;