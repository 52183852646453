import qs from 'qs';

export type GetAnalyticsParams = Record<string, string>;

const getTimestamp = () => {
  return new Date().toISOString();
};

const getReferrer = () => {
  if (typeof document !== 'undefined') {
    return document.referrer || 'direct';
  }
  return 'direct';
};

const getDeviceType = () => {
  if (typeof window !== 'undefined') {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    }
    if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return 'mobile';
    }
    return 'desktop';
  }
  return 'unknown';
};

const getEntryPoint = () => {
  if (typeof window !== 'undefined') {
    return `${window.location.pathname}${window.location.search}`;
  }
  return '';
};

export const getAnalyticsParams = (localFP: string) => {
  const url = new URL(window.location.href);
  const search = qs.parse(url.search, { ignoreQueryPrefix: true });
  const listConstParams = ['fp', 'timestamp', 'device_type', 'entry_point', 'referrer'];
  const params = (Object.entries(search) || []).reduce((acc: GetAnalyticsParams, [k, v]) => {
    const contain = listConstParams.includes(k) || k.match(/\butm_/);
    if (contain && v) {
      acc[k] = v.toString();
    }
    return acc;
  }, {});

  const {
    fp, referrer, timestamp, device_type, entry_point,
  } = params ?? {};

  return {
    ...params,
    fp: fp || localFP,
    referrer: referrer || getReferrer(),
    timestamp: timestamp || getTimestamp(),
    device_type: device_type || getDeviceType(),
    entry_point: entry_point || getEntryPoint(),
  };
};
