export const itemsMarketplace = [{
  label: 'Models',
  href: '/marketplace/models',
}, {
  label: 'Datasets',
  href: '/marketplace/datasets',
  disabled: true,
}];

export const itemsMyContent = [{
  label: 'My Offers',
  href: '/my-offers',
}];

export const getActiveRoute = (path: string) => {
  return {
    marketplace: ['/marketplace', '/marketplace/models', '/marketplace/datasets'].includes(path),
    mycontent: ['/my-files', '/offers'].includes(path),
  };
};
