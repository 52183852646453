import { useEffect, useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'lib/hooks';
import { trackEvent } from 'lib/features/events/thunks';
import { fpSelector, setFP } from 'lib/features/analytics';
import { getAnalyticsParams } from './helpers';

export const useReferrer = () => {
  const dispatch = useAppDispatch();
  const fp = useAppSelector(fpSelector);

  const sendMetrics = useCallback(() => {
      const referals = getAnalyticsParams(fp);
      dispatch(trackEvent({
        eventType: 'referrer',
        property: { referals, fp: referals.fp },
      }));
      dispatch(setFP(referals.fp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
  
    useEffect(() => {
      sendMetrics();
    }, [sendMetrics]);
};
