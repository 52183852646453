import Image from 'next/image';

import { Button } from 'uikitv2/Buttons/Button';
import { SocialBtnProps } from './types';
import classes from './SocialBtn.module.scss';

export const SocialBtn = ({
  icon, onClick, disabled, loading,
}: SocialBtnProps) => {
  const {
    alt,
    height,
    width,
    label,
    src,
  } = icon;

  return (
    <Button
      loading={loading}
      variant="black"
      onClick={onClick}
      className={classes.btn}
      classNameLink={classes.link}
      disabled={disabled}
    >
      <Image
        {...{
          alt, height, width, label, src,
        }}
        className={classes.img}
        unoptimized
      />
      <span className={classes.label}>{label}</span>
    </Button>
  );
};
